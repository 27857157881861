<template>
  <div class="panel d-flex align-items-md-center">
    <b-form-checkbox
      size="lg"
      v-model="value"
      @change="v => $emit('input', v)"
      :class="[{ error: v && v.$error }]"
      ref="input"
    >
      {{ text }}
      <router-link class="link" v-if="hasLink" target="_blank" :to="linkTo">{{
        linkText
      }}</router-link>
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: "input-check-box",
  props: {
    text: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: Boolean
    },
    hasLink: {
      required: false,
      type: Boolean
    },
    linkText: {
      required: false,
      type: String
    },
    linkTo: {
      required: false,
      type: String
    },
    v: {
      required: false,
      type: Object
    }
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event);
    },
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style scoped>
.panel {
  font-size: 17px;
}
::v-deep .custom-control.error .custom-control-label::before {
  border-color: red !important;
}
::v-deep .custom-control-label::before {
  border-radius: 0 !important;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #bcbcbc;
  background-color: #f47306;
  border-radius: 0;
}
::v-deep
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  cursor: pointer !important;
  border-color: #bcbcbc;
}
::v-deep .custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
::v-deep .custom-checkbox.b-custom-control-lg > label,
::v-deep .input-group-lg .custom-checkbox > label {
  font-size: 17px;
  line-height: 24px;
}
::v-deep .custom-checkbox.b-custom-control-lg .custom-control-label::before,
::v-deep .input-group-lg .custom-checkbox .custom-control-label::before,
::v-deep .custom-checkbox.b-custom-control-lg .custom-control-label::after,
::v-deep .input-group-lg .custom-checkbox .custom-control-label::after {
  top: 3px;
}
.link {
  text-decoration: underline;
}
</style>
